






















































import { Getter, namespace } from 'vuex-class'
import { Account, AccountTransactionSummary } from '@/models'
import { Component, Vue } from 'vue-property-decorator'
import { Filters, SelectedBarchartAttributes } from './types'

import dayjs from 'dayjs'

import AppChannelSubscriber from '@/components/AppChannelSubscriber'
import AccountTransactionChart from './components/AccountTransactionChart'
import ChartFilters from './components/AccountTransactionChartFilters.vue'
import AppAccountTransactionsTable from '@/components/AppAccountTransactionsTable'

Vue.component('ChartFilters', ChartFilters)
Vue.component('AppChannelSubscriber', AppChannelSubscriber)
Vue.component('AccountTransactionChart', AccountTransactionChart)
Vue.component('AppAccountTransactionsTable', AppAccountTransactionsTable)

const Auth = namespace('auth')

@Component
export default class Reports extends Vue {
  @Auth.Getter uid!: string
  @Auth.Getter client!: string
  @Auth.Getter accessToken!: string

  awaitingWorkerJobCompletion = false
  areSummariesAutoRefreshed = false

  inflowTotals: AccountTransactionSummary[] = []
  outflowTotals: AccountTransactionSummary[] = []

  commonQueryFilters: Filters = {
    accountId: { eq: 'null' },
    includesEquity: true
  }

  filteredAccountName = 'All Accounts'
  showAdjustments = true

  barchartSelected = false

  accountParam: string | null = null
  investmentParam: { eq: 'null' } | null = null
  transactionDateParam: (string | null)[] = []
  amountTypeParam: 'inflow' | 'outflow' | null = null
  /* es-lint disable-next-line */
  draftTypeParam: { not_eq: ['adjustment', 'initial_adjustment'] } | null = null

  async mounted () {
    await this.fetchAccountTransactionSummaries()
    if (this.summariesMustInitialize) {
      await this.runSummaryUpdateJob()
      await this.fetchAccountTransactionSummaries()
    }
  }

  async fetchAccountTransactionSummaries () {
    this.awaitingWorkerJobCompletion = false
    this.inflowTotals = await AccountTransactionSummary
      .fetchLastTwelveMonths({
        transactionType: 'inflow',
        ...this.commonQueryFilters
      })

    this.outflowTotals = await AccountTransactionSummary
      .fetchLastTwelveMonths({
        transactionType: 'outflow',
        ...this.commonQueryFilters
      })
  }

  get summariesMustInitialize () {
    return this.dataIsMissing && !this.areSummariesAutoRefreshed
  }

  get dataIsMissing () {
    return !this.inflowTotals.length || !this.outflowTotals.length
  }

  get dateRangeSubtitle () {
    if (this.dataIsMissing) return ''
    const firstSummary = this.inflowTotals[0]
    const lastSummary = this.inflowTotals[this.inflowTotals.length - 1]
    return `${firstSummary.monthName}, ${firstSummary.year} - ${lastSummary.monthName}, ${lastSummary.year}`
  }

  async runSummaryUpdateJob () {
    this.awaitingWorkerJobCompletion = true
    Vue.prototype.$api.defaults.headers.common.uid = this.uid
    Vue.prototype.$api.defaults.headers.common.client = this.client
    Vue.prototype.$api.defaults.headers.common['access-token'] = this.accessToken
    await Vue.prototype.$api.post('api/v1/account_transaction_summaries/refresh')
  }

  async setAccountFilter (account: Account | null) {
    if (account === null) {
      this.commonQueryFilters.accountId = { eq: 'null' }
      this.filteredAccountName = 'All Accounts'
      this.accountParam = null
    } else {
      this.commonQueryFilters.accountId = account.id
      this.filteredAccountName = account.name
      this.accountParam = account.id!
    }
    await this.fetchAccountTransactionSummaries()
  }

  async toggleIncludesEquity (includesEquity: boolean) {
    this.commonQueryFilters.includesEquity = includesEquity
    this.investmentParam = includesEquity ? null : { eq: 'null' }
    await this.fetchAccountTransactionSummaries()
  }

  toggleShowAdjustments (showAdjustments: boolean) {
    this.showAdjustments = showAdjustments
    this.draftTypeParam = this.showAdjustments
      ? null
      /* eslint-disable-next-line */
      : { not_eq: ['adjustment', 'initial_adjustment'] }
  }

  setSelectedSummaryTableParams ({ month, year, transactionType }: SelectedBarchartAttributes) {
    const date = new Date(year, month - 1, 1)
    const transactionDateParam = [
      dayjs(date).format('YYYY-MM-DD'),
      dayjs(date).endOf('month').format('YYYY-MM-DD')
    ]
    this.transactionDateParam = [...transactionDateParam]
    this.amountTypeParam = transactionType
    this.barchartSelected = true
  }
}
