





















































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { AccountDropdownEvent } from '../types'
import { Account, AccountTransactionSummary } from '@/models'

import appTime from '@/filters/app_time'
import appDate from '@/filters/app_date'

import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Accordion from 'primevue/accordion'
import InputSwitch from 'primevue/inputswitch'

Vue.component('Button', Button)
Vue.component('Dropdown', Dropdown)
Vue.component('Accordion', Accordion)
Vue.component('InputSwitch', InputSwitch)

@Component
export default class AccountTransactionChartFilters extends Vue {
  @Prop() awaitingWorkerJobCompletion!: boolean
  @Prop() showAdjustments!: boolean

  includesEquity = true

  accounts: Account[] = []
  selectedAccount: Account | null = null
  lastUpdatedAt = ''

  mounted () {
    this.getLastUpdatedAt()
    this.getAccounts()
  }

  async getLastUpdatedAt () {
    console.log('getting stuff')
    const { meta } = await AccountTransactionSummary
      .stats({ updatedAt: 'maximum' })
      .per(0)
      .all()

    const timestamp = meta.stats.updated_at.maximum
    if (!timestamp) return

    const date = appDate(timestamp)
    const time = appTime(timestamp)
    this.lastUpdatedAt = `Last updated: ${date} at ${time}`
  }

  async getAccounts () {
    this.accounts = (await Account
      .page(1)
      .per(1000)
      .select(['id', 'name'])
      .order('name')
      .all())
      .data
  }

  @Emit()
  setAccountFilter (event: AccountDropdownEvent) {
    const account: Account | null = event.value
    return account
  }

  @Emit()
  runSummaryUpdateJob () { return null }

  @Emit()
  setYAxisAttr () {
    return !this.showAdjustments
  }

  @Emit()
  toggleIncludesEquity () {
    this.includesEquity = !this.includesEquity
    return this.includesEquity
  }

  @Watch('awaitingWorkerJobCompletion')
  onAwaitingWorkerJobCompletionChanged (newValue: boolean) {
    if (!newValue) this.getLastUpdatedAt()
  }
}
