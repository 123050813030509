
import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import Pusher from 'pusher-js'

@Component
export default class AppChannelSubscriber extends Vue {
  @Prop() channel!: string
  @Prop() event!: string

  mounted () {
    this.subscribeToChannel()
  }

  subscribeToChannel () {
    const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: 'us2'
    })
    const channel = pusher.subscribe(this.channel)
    channel.bind(this.event, this.emitEvent)
  }

  @Emit()
  emitEvent (data: any) {
    return data
  }
}
