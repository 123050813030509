




import { Component, Prop, Watch, Vue, Emit } from 'vue-property-decorator'
import { Account, AccountTransactionSummary } from '@/models'
import { Getter, namespace } from 'vuex-class'

import { SeriesProps, SelectedBarchartAttributes } from '../types'

import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

const Auth = namespace('auth')

@Component
export default class AccountTransactionsChart extends Vue {
  @Auth.Getter accessToken!: string
  @Auth.Getter client!: string
  @Auth.Getter uid!: string
  @Prop() inflowTotals!: AccountTransactionSummary[]
  @Prop() outflowTotals!: AccountTransactionSummary[]
  @Prop() showAdjustments!: boolean

  inflowTooltipText = '{name}: $[bold]{valueY}[/]'
  outflowTooltipText = '{name}: ( $[bold]{valueY}[/] )'

  cashflowChart!: am4charts.XYChart

  async mounted () {
    this.cashflowChart = am4core.create('cashflow-chart', am4charts.XYChart)
    this.configureCashflowChart()
  }

  beforeDestroy () {
    if (this.cashflowChart) {
      this.cashflowChart.dispose()
    }
  }

  async refreshChart () {
    this.cashflowChart.series.removeIndex(0).dispose()
    this.cashflowChart.series.removeIndex(0).dispose()
    this.addEachSeries()
  }

  configureCashflowChart () {
    am4core.useTheme(am4themesAnimated)
    this.applyChartSettings()
    this.makeXAxis()
    this.makeYAxis()
    this.addEachSeries()
  }

  applyChartSettings () {
    this.cashflowChart.width = am4core.percent(100)
    this.cashflowChart.height = am4core.percent(100)
    this.cashflowChart.legend = new am4charts.Legend()
    // the cashflow chart needs any of the datasets containing
    // x-axis values for displaying month - they're interchangeable
    this.cashflowChart.data = this.inflowTotals
  }

  makeXAxis () {
    const xAxis = this.cashflowChart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'displayMonth'
    xAxis.renderer.grid.template.strokeOpacity = 0.09
    xAxis.renderer.grid.template.location = 0
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.minGridDistance = 20
  }

  makeYAxis () {
    const yAxis = this.cashflowChart.yAxes.push(new am4charts.ValueAxis())
    yAxis.numberFormatter = new am4core.NumberFormatter()
    yAxis.numberFormatter.numberFormat = "'$'###,###.##"
    yAxis.renderer.grid.template.strokeOpacity = 0.09
    yAxis.title.text = '$ Amount'
  }

  addEachSeries () {
    this.makeInflowBarchartSeries()
    this.makeOutflowBarchartSeries()
  }

  makeBasicSeries (props: SeriesProps) {
    const series = this.cashflowChart.series.push(new am4charts.ColumnSeries())
    series.name = props.name
    series.data = props.data
    series.fill = props.fill
    series.stacked = props.stacked
    series.dataFields.valueY = props.value
    series.dataFields.categoryX = 'displayMonth'
    series.columns.template.width = am4core.percent(95)
    this.configureTooltip(series, props.tooltipText, props.tooltipTextColor)
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer
    series.columns.template.events.on('hit', function (e) {
      const summaryRecord = e.target.dataItem!.dataContext
      this.setSelectedSummaryTableParams(summaryRecord as unknown as AccountTransactionSummary)
    }, this)
    return series
  }

  makeInflowBarchartSeries () {
    this.makeBasicSeries({
      data: this.inflowTotals,
      tooltipTextColor: 'white',
      tooltipText: this.inflowTooltipText,
      fill: this.makeInflowGradient(1),
      value: this.yAxisAttr,
      name: 'Inflows',
      stacked: false
    })
  }

  makeOutflowBarchartSeries () {
    this.makeBasicSeries({
      data: this.outflowTotals,
      tooltipTextColor: 'black',
      tooltipText: this.outflowTooltipText,
      fill: this.makeOutflowGradient(1),
      value: this.yAxisAttr,
      name: 'Outflows',
      stacked: false
    })
  }

  get yAxisAttr () {
    return this.showAdjustments
      ? 'transactionTotal'
      : 'transactionTotalExcludingAdjustments'
  }

  configureTooltip (series: am4charts.ColumnSeries, tooltipText: string, textColor: string) {
    series.columns.template.tooltipText = tooltipText
    series.tooltip!.pointerOrientation = 'down'
    series.columns.template.tooltipX = am4core.percent(50)
    series.columns.template.tooltipY = am4core.percent(0)
    series.tooltip!.hiddenState.transitionDuration = 100
    series.tooltip!.autoTextColor = false
    series.tooltip!.label.fill = am4core.color(textColor)
  }

  generateGradient (colors: string[]) {
    const gradient = new am4core.LinearGradient()
    colors.forEach(color => gradient.addColor(am4core.color(color)))
    return gradient
  }

  makeInflowGradient (variant: 1 | 2 | 3) {
    const gradientVariants = {
      1: ['#271b4b', '#363962'],
      2: ['#46cad6', '#06484f'],
      3: ['#43efaa', '#0b6b44']
    }
    return this.generateGradient(gradientVariants[variant])
  }

  makeOutflowGradient (variant: 1 | 2 | 3) {
    const gradientVariants = {
      1: ['#d6a12f', '#eddb5a'],
      2: ['#46cad6', '#664a0e'],
      3: ['#d85229', '#70260f']
    }
    return this.generateGradient(gradientVariants[variant])
  }

  @Watch('showAdjustments')
  updateYAxisAttr () {
    this.cashflowChart.series.values.forEach(series => {
      series.dataFields.valueY = this.yAxisAttr
      this.cashflowChart.invalidateRawData()
    })
  }

  @Watch('outflowTotals')
  // this indirectly watches inflow totals
  // the two arrays always change together
  // use outflows to trigger refresh because
  // it updates immediately after inflows
  // watching both causes duplicate fetch
  refreshChartOnInflowsDataChange () {
    this.refreshChart()
  }

  @Emit()
  setSelectedSummaryTableParams (summary: AccountTransactionSummary): SelectedBarchartAttributes {
    return {
      transactionType: summary.transactionType,
      month: summary.month,
      year: summary.year
    }
  }
}
